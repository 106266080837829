<template>
    <b-modal
        id="confirm-modal-success"
        no-close-on-backdrop
        no-close-on-esc
        hide-header
        hide-footer
        centered
        v-model="modalShow"
    >
        <div class="d-flex flex-column align-items-center justify-center-center my-2">
            <feather-icon
                id="view-modal-success"
                icon="CheckIcon"
                :class="'text-'+type+ ' mb-2'"
                size="80"
            />
            <div class="text-center">
                <span>{{ infoTextModal }}</span>
            </div>
            <div class="d-flex mt-2">
                <b-button :disabled="loading" :variant="'outline-'+type" @click="confirmAction" >
                    <span v-if="loading" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                        {{ action }}
                </b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue';
export default {
    components: {
        BModal, BButton
    },

    props: {
        modalShow: {
            required: true,
            type: Boolean
        },
        typeModal: {
            required: true,
            type: String
        },
        caseModal: {
            required: true,
            type: String
        },
        infoText: {
            type: String
        }
    },

    data() {
        return {
            loading: false,
            textComplement: '',
            modalTypes: [
                {
                    'type': 'success',
                    'case': 'criacao',
                    'action': 'Ok!',
                    'infoText': `O registro foi criado com sucesso!`
                },
                {
                    'type': 'success',
                    'case': 'edicao',
                    'action': 'Ok!',
                    'infoText': `O registro foi alterado com sucesso!`
                },
                {
                    'type': 'success',
                    'case': 'inativacao',
                    'action': 'Ok!',
                    'infoText': `O registro foi inativado com sucesso!`
                },
                {
                    'type': 'success',
                    'case': 'cancelamento',
                    'action': 'Ok!',
                    'infoText': `O registro foi cancelado com sucesso!`
                },
                {
                    'type': 'success',
                    'case': 'edit-schedule',
                    'action': 'Ok!',
                    'infoText': `O cronograma da campanha foi alterado com sucesso!`
                }
            ],
            type: '',
            case: '',
            action: '',
            infoTextModal: ''
        }
    },

    methods: {
        confirmAction(modalEvent) {
            modalEvent.preventDefault();
            this.loading = true;
            this.$emit('confirmed', modalEvent);
        }
    },

    watch: {
        modalShow() {
            this.loading = false;

            this.modalTypes.map(item => {
                if(item.case == this.$props.caseModal) {
                    this.action = item.action;
                    this.case = item.case;
                    this.infoTextModal = this.$props.infoText ? this.$props.infoText : item.infoText;
                    this.type = item.type;
                }
            });
        }
    }
}
</script>

